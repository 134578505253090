import * as index from '../page/index/script/index'
import * as globalWarming from '../page/global-warming/script/global-warming'
import * as playlistConcerts from '../page/playlist-concerts/script/app.jsx'


// start/end page scripts
const pageStart = () => {
  // index
  if( document.documentElement.classList.contains( 'splash' ) ){
    index.start()
  }
  // global warming
  else if( document.body.classList.contains( 'global-warming' ) ){
    globalWarming.start()
  }
  // playlist concerts
  if( document.documentElement.classList.contains( 'playlist-concerts' ) ){
    playlistConcerts.start()
  }
}
pageStart()

const pageEnd = () => {
  // index
  if( document.documentElement.classList.contains( 'splash' ) ){
    index.end()
  }
  // global warming
  else if( document.body.classList.contains( 'global-warming' ) ){
    globalWarming.end()
  }
  // playlist concerts
  if( document.documentElement.classList.contains( 'playlist-concerts' ) ){
    playlistConcerts.end()
  }
}


const route = pageHref => {
  // get content
  return fetch( pageHref )
    .then( response => {
      // successful connection
      if( response.ok ){
        return response.text().then( responseText => {
          // page content
          document.querySelector( 'article.content' ).innerHTML =
            responseText.match( /<article class="content">([^]*)<\/article>/i )[ 1 ]

          // kill unnecessary scripts
          pageEnd()

          // update html class
          const htmlClass = responseText.match( /<html(.*?)>/i )[ 1 ].match( /class="(.*?)"/ )
          if( htmlClass ){
            document.documentElement.className = htmlClass[ 1 ]
          }
          else{
            document.documentElement.className = ''
          }

          // update body class
          const bodyClass = responseText.match( /<body(.*?)>/i )[ 1 ].match( /class="(.*?)"/ )
          if( bodyClass ){
            document.body.className = bodyClass[ 1 ]
          }
          else{
            document.body.className = ''
          }

          // page scripts
          pageStart()

          // close menu
          document.body.classList.remove( 'show-menu-overlay' )

          // page title
          const decodeSpecialCharacters = document.createElement( 'div' )
          decodeSpecialCharacters.innerHTML = responseText.match( /<title>(.*?)<\/title>/i )[ 1 ]
          document.title = decodeSpecialCharacters.textContent
        } )
      }
      throw new Error( 'Network response was not ok.' )
    } )
    .catch( error => {
      console.log( error )
    } )
}


export default route