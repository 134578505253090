import React, { useEffect, useState } from 'react'

import config from '../../../../config'
import locationHash from '../../service/locationHash'
import { Playlist } from './component/Playlist/index.jsx'
import { Submit } from './component/Submit/index.jsx'

import styles from './index.module.css'

const FETCH_INIT = {
  headers: {
    'Authorization': 'Bearer ' + locationHash.access_token
  }
}

export const Playlists = ({
  playlists,
  setAuthorized,
  setConcerts,
  setLocations,
  setPlaylists
}) => {
  const [ loading, setLoading ] = useState( false )
  const [ previousSelectionIndex, setPreviousSelectionIndex ] = useState( null )
  
  const handleClickPlaylist = ( e ) => {
    e.preventDefault()

    let playlistsUpdate = [ ...playlists ]
    let clickedPlaylistIndex = playlists.findIndex( playlist => (
      playlist.href ===
        e.currentTarget.querySelector( 'input[ type="checkbox" ]' ).value
    ) )

    // select/deselect multiple playlists with shift key
    if(
      e.shiftKey &&
      previousSelectionIndex !== null &&
      previousSelectionIndex !== clickedPlaylistIndex
    ){
      const choice = !playlists[ clickedPlaylistIndex ].checked
      for(
        let i = Math.min( clickedPlaylistIndex, previousSelectionIndex );
        i <= Math.max( clickedPlaylistIndex, previousSelectionIndex );
        i++
      ){
        playlistsUpdate[ i ].checked = choice
      }
    }
    
    // select/deselect single playlist
    else{
      playlistsUpdate[ clickedPlaylistIndex ].checked = !playlists[ clickedPlaylistIndex ].checked
    }

    setPlaylists( playlistsUpdate )
    setPreviousSelectionIndex( clickedPlaylistIndex )
  }

  const handleSubmit = async ( e ) => {
    e.preventDefault()

    setLoading( true )

    // Spotify: artists from playlists
    const getArtists = () =>
      Promise.all(
        playlists
          .filter( playlist => playlist.checked )
          .map( playlist => fetch( playlist.href, FETCH_INIT ) )
      )
        .then( responses => {
          return Promise.all( responses.map( response => response.json() ) )
            .then( responseJSON => {
              // testing: verify playlist data
              if(
                location.search.slice( 1 ).split( '&' ).find( q => q === 'v=debug' )
              ){
                const concertContainer = document.querySelector( '.concert' );
                responseJSON.forEach( ( playlist, i ) => {
                  const playlistLabel = document.createElement( 'h4' ),
                    listTrack = document.createElement( 'ol' )
                  
                  // playlist name
                  playlistLabel.textContent = playlists.filter( playlist => playlist.checked )[ i ].name
                  concertContainer.appendChild( playlistLabel )

                  // playlist tracks
                  playlist.items.forEach( ( track, j ) => {
                    let trackData = ''
                    track.track.artists.forEach( artist => {
                      trackData += artist.name + ', '
                    } )
                    trackData =
                      trackData.slice( 0, -2 ) + ': ' +
                      '&ldquo;' + track.track.name + '&rdquo; ' +
                      '/ ' + track.track.album.name

                    const listTrackItem = document.createElement( 'li' )
                    listTrackItem.innerHTML = trackData
                    listTrack.appendChild( listTrackItem )
                  } )
                  concertContainer.appendChild( listTrack )
                } )

                // test does not require artists to build concerts from
                return {}
              }

              // regular functionality
              else{
                const artists = {}
                for( let i = 0; i < responseJSON.length; i++ ){
                  responseJSON[ i ].items.forEach( item => {
                      item.track.artists.forEach( artist => {
                        if( !artists.hasOwnProperty( artist.name.toLowerCase() ) ){
                          artists[ artist.name.toLowerCase() ] = {
                            name: artist.name,
                            query: artist.name.replace( /&|\?/g, ' ' ).replace( /\s+/g, '+' ).toLowerCase()
                          }
                        }
                      } )
                  } )
                }
                return artists
              }
            } )
        } )
        .catch( error => {
          // TO DO: show error or retry
          console.error( error )
        } )
    const artists = await getArtists()

    // Songkick: artist ids from artist names
    const searchArtist = ( name, query ) =>
      fetch( 'https://api.songkick.com/api/3.0/search/artists.json?apikey=' + config.songkick.api_key + '&query=' + query )
        .then( response => response.json() )
        .then( responseJSON => {
          return {
            match: name,
            response: responseJSON
          }
        } )
    await Promise.all(
      Object.keys( artists ).map( name => searchArtist( name, artists[ name ].query ) )
    )
      .then( foundArtist => {
        let found,
          match
        for( let i = 0; i < foundArtist.length; i++ ){
          found = foundArtist[ i ].response.resultsPage.results.artist
          match = foundArtist[ i ].match
          if( found ){
            for( let j = 0; j < found.length; j++ ){
              if( found[ j ].displayName.toLowerCase() === match ){
                artists[ match ][ 'id' ] = found[ j ].id
              }
            }
          }
          else{
            delete artists[ match ]
          }
        }
      } )
      .catch( error => {
        // TO DO: show error or retry
        console.error( error )
      } )

    // Songkick: upcoming events from artists
    const concerts = {}
    const locations = []
    await Promise.all(
      Object.keys( artists ).map(
        artist =>
          fetch( 'https://api.songkick.com/api/3.0/artists/' + artists[ artist ].id + '/calendar.json?apikey=' + config.songkick.api_key )
      )
    )
      .then( responses => Promise.all( responses.map( response => response.json() ) ) )
      .then( responseJSON => {
        let events,
          details
        for( let i = 0; i < responseJSON.length; i++ ){
          event = responseJSON[ i ].resultsPage.results.event || []
          for( let j = 0; j < event.length; j++ ){
            let location = event[ j ].location.city.split( ', ' )
            location =
              location.length === 2 ?
                {
                  city: location[ 0 ],
                  country: location[ 1 ]
                } :
                  {
                    city: location[ 0 ],
                    country: location[ 2 ],
                    state: location[ 1 ]
                  }

            details = {
              artist: [],
              name: event[ j ].displayName,
              location: {
                city: location.city,
                country: location.country,
                label: event[ j ].location.city,
                state: location.state
              },
              time: event[ j ].start.time,
              uri: event[ j ].uri
            }
            event[ j ].performance.forEach( artist => details.artist.push( artist.displayName ) )

            if( !concerts.hasOwnProperty( event[ j ].start.date ) ){
              concerts[ event[ j ].start.date ] = [ details ]
            }
            else{
              concerts[ event[ j ].start.date ].push( details )
            }

            // add location to aggregate
            const expandCountryValue = ( country ) => {
              if( country === 'US' ){
                return [ 'US', 'USA', 'United States' ]
              }
              if( country === 'UK' ){
                return [ 'UK', 'United Kingdom' ]
              }
              // etc
              return [ country ]
            }
            if( !locations.some( l => l.label === location.country ) ){
              locations.push({
                label: location.country,
                type: 'country',
                value: expandCountryValue( location.country )
              })
            }
            
            const expandStateValue = ( state, country ) => {
              const values = [ state, `${state} ${country}` ]
              if( state === 'AK' ){
                values.push( 'Alaska' )
              }
              if( state === 'AR' ){
                values.push( 'Arkansas' )
              }
              if( state === 'AZ' ){
                values.push( 'Arizona' )
              }
              if( state === 'CA' ){
                values.push(  'California' )
              }
              if( state === 'TX' ){
                values.push( 'Texas' )
              }
              // etc

              return values
            }
            if(
              location.state &&
              !locations.some( l => (
                l.label === `${location.state}, ${location.country}`
              ))
            ){
              locations.push({
                label: `${location.state}, ${location.country}`,
                type: 'state',
                value: expandStateValue( location.state, location.country )
              })
            }
            
            const expandCityValue = ( city, state, country ) => {
              const values = [ city, `${city} ${country}` ]
              if( state ){
                values.push( `${city} ${state}` )
                // expanded states...
              }
              // expanded countries...
              
              return values
            }
            if( !locations.some( l => l.label === event[ j ].location.city ) ){
              locations.push({
                label: event[ j ].location.city,
                type: 'city',
                value: expandCityValue( location.city, location.state, location.country )
              })
            }
          }
        }
      } )

    // save concerts to state
    const concert = [],
      dateInt = ( date ) => Number( date.replace( /-/g, '' ) ),
      weekday = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
      month = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
    Object.keys( concerts )
      .sort( ( a, b ) => dateInt( a ) - dateInt( b ) )
      .forEach( date => {
        // https://stackoverflow.com/a/31732581
        let displayDate = new Date( date.replace( /-/g, '/' ) )
        displayDate = weekday[ displayDate.getDay() ] + ', ' + month[ displayDate.getMonth() ] + ' ' + displayDate.getDate()

        const concertsOnDate = []
        let showDetails
        for( let i = 0; i < concerts[ date ].length; i++ ){
          showDetails = concerts[ date ][ i ]

          concertsOnDate.push({
            name: showDetails.name,
            href: showDetails.uri,
            artists: showDetails.artist.join( ', ' ),
            location: showDetails.location,
            time: showDetails.time
          })
        }

        concert.push({
          [ displayDate ]: concertsOnDate
        })
      } )
    setConcerts( concert )
    
    setLocations( locations )

    setLoading( false )
  }

  // get user playlists
  useEffect( () => {
    fetch( 'https://api.spotify.com/v1/me/playlists?limit=50', FETCH_INIT )
      .then( response => {
        if( response.ok ){
          return response.json().then( responseJSON => {
            const userPlaylist = []
            responseJSON.items.forEach( item =>
              userPlaylist.push({
                checked: false,
                count: item.tracks.total,
                href: item.tracks.href,
                name: item.name
              })
            )
            setPlaylists( userPlaylist )
          } )
        }
        throw new Error( 'Network response was not ok.' )
      } )
      .catch( error => {
        setAuthorized( false )
      } )
  }, [] )

  return(
    <form className={styles.container} onSubmit={handleSubmit}>
      <div className={styles.playlists}>
        {
          playlists.map( ( playlist, i ) =>
            <Playlist
              key={i}
              name={playlist.name}
              count={playlist.count}
              href={playlist.href}
              checked={!!playlist.checked}
              onClick={handleClickPlaylist}
              />
          )
        }
      </div>

      <Submit disabled={loading || ( playlists.filter( option => option.checked ).length ? false : true )} />
    </form>
  )
}