import '../style.css'
import route from '../../../script/route'
const historyAPI = history && history.pushState // necessary check with babel?
import palette from '../../../script/palette'


// back/forward
if( historyAPI ){
  window.onpopstate = () => {
    route( location.href )

    // Google Anayltics
    //ga( 'send', 'pageview' )
  }
}

const overlayMenu = {
  btn: {
    menu: document.querySelector( 'button.menu' ),
    home: document.querySelector( 'a.home' ),
    'global-warming': document.querySelector( '.menu-overlay a.global-warming' ),
    'playlist-concerts': document.querySelector( '.menu-overlay a.playlist-concerts' ),
    'graphic-design': document.querySelector( '.menu-overlay a.graphic-design' )
  },
  el: document.querySelector( '.menu-overlay' ),
  size: () => {
    var mobile = window.innerWidth < 768,
      typeContainer = document.querySelector( '.menu-overlay__type' ),
      svgWidth = mobile ? document.querySelector( '.menu-overlay__font-small' ).getAttribute( 'width' ) : document.querySelector( '.menu-overlay__font-large' ).getAttribute( 'width' ),
      svgHeight = mobile ? document.querySelector( '.menu-overlay__font-small' ).getAttribute( 'height' ) : document.querySelector( '.menu-overlay__font-large' ).getAttribute( 'height' ),
      menuWidth =
        Math.min(
          mobile ? window.innerWidth - 30 : window.innerWidth * .85 - 30,
          1170
        )

    typeContainer.style.width = menuWidth + 'px'
    typeContainer.style.height = svgHeight * ( menuWidth / svgWidth ) + 'px'
  }
}
if( overlayMenu.btn.menu ){
  overlayMenu.btn.menu.addEventListener(
    'click',
    function(){
      document.body.classList.toggle( 'show-menu-overlay' )
    }
  )
}
/*if( overlayMenu.btn.home )
  overlayMenu.btn.home.addEventListener(
    'click',
    function(){
      document.body.classList.remove( 'show-menu-overlay' )
    }
  );*/
document.addEventListener(
  'keydown',
  function( e ){
    if( e.keyCode === 27 ) //escape key
      document.body.classList.remove( 'show-menu-overlay' )
  }
);
function overlayMenuListener( name ){
  // hover states
  overlayMenu.btn[ name ].addEventListener(
    'mouseenter',
    () => {
      const highlight = document.querySelector( '.menu-overlay__highlight-page' ),
        link = document.querySelector( '.menu-overlay a.' + name )

      if( name === 'home' ){
        document.querySelector( '.menu-overlay__highlight-home' ).classList.add( 'menu-overlay__highlight-home--on' )
      }
      else{
        const linkStyle = getComputedStyle( link )

        highlight.style.top = linkStyle[ 'top' ]
        highlight.style.left = linkStyle[ 'left' ]
        highlight.style.width = linkStyle[ 'width' ]
        highlight.style.height = linkStyle[ 'height' ]

        document.querySelector( '.menu-overlay__highlight-page' ).classList.add( 'menu-overlay__highlight-page--on' )
      }
    }
  );
  overlayMenu.btn[ name ].addEventListener(
    'mouseleave',
    () => {
      document.querySelector( '.menu-overlay__highlight-home' ).classList.remove( 'menu-overlay__highlight-home--on' )
      document.querySelector( '.menu-overlay__highlight-page' ).classList.remove( 'menu-overlay__highlight-page--on' )
    }
  );

  // navigation
  overlayMenu.btn[ name ].addEventListener(
    'click',
    e => {
      // do not disrupt command or middle clicks default behavior
      if( e.metaKey || e.which === 2 ){
        return true
      }

      if( location.href === e.currentTarget.href ){
        e.preventDefault()

        return document.body.classList.remove( 'show-menu-overlay' )
      }
      if( historyAPI ){
        e.preventDefault()

        history.pushState( null, null, e.currentTarget.href )
        //const href = e.currentTarget.href
        route( e.currentTarget.href )
          //.then( () => history.pushState( null, null, href ) )
      }
    }
  )
}
for( var key in overlayMenu.btn ){
  if( overlayMenu.btn.hasOwnProperty( key ) && overlayMenu.btn[ key ] && key !== 'menu' )
    overlayMenuListener( key );
}
overlayMenu.size();
addEventListener( 'load', overlayMenu.size );
addEventListener( 'resize', overlayMenu.size );

/*
// duplicate svg for hover states
var hoverSVG = document.querySelector( '.menu-overlay svg' ).cloneNode( true );
hoverSVG.setAttribute( 'class', 'on' );
hoverSVG.removeChild( hoverSVG.querySelector( '.diagonal' ) );
document.querySelector( '.menu-overlay__type' ).insertBefore( hoverSVG, document.querySelector( '.menu-overlay h1' ) )
*/


// highlight color
setInterval(
  () => {
    document.querySelector( '.menu-overlay__highlight-home' ).style.backgroundColor = 'hsl( ' + palette.hue[ 0 ] + ', 100%, 50% )'
    document.querySelector( '.menu-overlay__highlight-page' ).style.backgroundColor = 'hsl( ' + palette.hue[ 0 ] + ', 100%, 50% )'
  },
  palette.changeInterval
)