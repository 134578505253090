const palette = {
  hue: [ Math.round( Math.random() * 359 ) ],
  //color: [] //put this data in splash script
  changeInterval: 100
}

const setPalette = () => {
  const removeSixty = n => {
    if( n - 60 >= 0 ){
      return n - 60
    }
    return 299 + n
  }
  const addTen = n => {
    if( n + 10 <= 359 ){
      return n + 10
    }
    return n + 10 - 359
  }

  palette.hue[ 0 ] = palette.hue[ 0 ] < 359 ? palette.hue[ 0 ] + 1 : 0;
  palette.hue[ 1 ] = removeSixty( palette.hue[ 0 ] );
  palette.hue[ 2 ] = addTen( palette.hue[ 1 ] );
  palette.hue[ 3 ] = removeSixty( palette.hue[ 2 ] );
  palette.hue[ 4 ] = addTen( palette.hue[ 3 ] );
  palette.hue[ 5 ] = removeSixty( palette.hue[ 4 ] );
}
setPalette()

setInterval(
  setPalette,
  palette.changeInterval
)

export default palette