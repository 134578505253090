const locationHash = {}
if( location.hash ){
  location.hash.slice( 1 ).split( '&' ).forEach(
    str => {
      str = str.split( '=' )
        locationHash[ str[ 0 ] ] = str[ 1 ]
    }
  )
}

export default locationHash;