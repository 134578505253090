import React, { useState } from 'react'

import { Concert } from './component/Concert/index.jsx'

import styles from './index.module.css'

export const ConcertDate = ({ concert, date }) => {
    const [ open, setOpen ] = useState( false )
  
    return(
      <li className={`${styles.container}${open ? ' ' + styles.open : ''}`}>
        <h3 className={styles.headline} onClick={() => { setOpen( !open ) }}>
          <span className={styles.date}>
            {date}
          </span>
        </h3>
        
        <ul className={styles.list}>
          {concert.map( ( concert, i ) =>
            <Concert
              key={i}
              name={concert.name}
              href={concert.href}
              artist={concert.artists}
              location={concert.location}
              time={concert.time}
              />
          )}
        </ul>
      </li>
    )
  }