import './style/normalize.css'
import './style/base.css'
import './style/grid.css'

import './component/nav/script/nav'
import './page/index/script/index'
import './page/global-warming/script/global-warming'
import './page/playlist-concerts/script/app.jsx'
import './page/graphic-design/script/graphic-design'


// U+25A1 □ WHITE SQUARE cubic net
console.log(
  "              □ □ □\n" +
  "              □ □ □\n" +
  "              □ □ □\n" +
  "                   \n" +
  "       □ □ □  □ □ □\n" +
  "       □ □ □  □ □ □\n" +
  "       □ □ □  □ □ □\n" +
  "                   \n" +
  "□ □ □  □ □ □       \n" +
  "□ □ □  □ □ □       \n" +
  "□ □ □  □ □ □       \n" +
  "                   \n" +
  "□ □ □              \n" +
  "□ □ □              \n" +
  "□ □ □              \n"
);