import React from 'react'

import styles from './index.module.css'

export const Option = ({ location, onClick }) => (
  <button
    className={styles.button}
    type="button"
    onClick={() => onClick( location )}
    >
    {location.label}
  </button>
)
