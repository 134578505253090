import React from 'react'

import styles from './index.module.css'

export const Concert = ({ artist, href, location, name, time }) =>
  <li className={styles.item}>
    <h4>
      <a
        className={styles.link}
        href={href}
        target="_blank">
        {name}
      </a>
    </h4>
    <strong>{artist}</strong>
    <p>{location.label}</p>
    {time &&
      <time dateTime={time}>{time}</time>
    }
  </li>