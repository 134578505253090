import React, { useEffect, useState } from 'react'

import { Option } from './component/Option/index.jsx'

import styles from './index.module.css'

export const Locations = ({ options, query, visibility, onClick }) => {
  const unfilteredOptions = o => o.map( option => option.label ).sort()
  const [ optionMatches, setOptionMatches ] = useState( unfilteredOptions( options ) )

  useEffect( () => {
    if( query ){
      const match = new RegExp( '^' + query, 'i' )
      setOptionMatches(
        options
          .filter( option =>
            option.value.some( o => match.test( o ) )
          )
          .map( option => option.label )
          .sort()
      )
    }
    else{
      setOptionMatches( unfilteredOptions( options ) )
    }
  }, [ options, query ] )

  return(
    <ol className={`${styles.container}${visibility ? ' ' + styles.open : ''}`}>
      {optionMatches.map( option => (
        <li key={option}>
          <Option
            location={options.find( o => o.label === option )}
            onClick={onClick}
            />
        </li>
      ) )}
    </ol>
  )
}