import React from 'react'

export const Playlist = ({ checked, count, href, name, onClick }) => (
  <label onClick={onClick}>
    <input
      type="checkbox"
      value={href}
      checked={checked}
      readOnly
      />
    <span>
      <strong>{name}</strong> ({count} tracks)
    </span>
  </label>
)