import React from 'react'

import styles from './index.module.css'

export const Submit = ({ disabled }) => (
  <button
    className={`${styles.button} btn-primary`}
    disabled={disabled}>
    &rarr; Concerts
  </button>
)
