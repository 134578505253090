import React from 'react'
import ReactDOM from 'react-dom'

import '../style.css'
import { PlaylistConcerts } from './component/PlaylistConcerts/index.jsx'

export const start = () => {
  ReactDOM.render(
    <PlaylistConcerts />,
    document.querySelector( '.playlist-concerts-app' )
  )
}

export const end = () => {
  const injectionNode = document.querySelector( '.playlist-concerts-app' )

  if( injectionNode ){
    ReactDOM.unmountComponentAtNode( injectionNode )
  }
}