import React from 'react'

import config from '../../../../config'

import styles from './index.module.css'

export const GetPlaylists = () => {
  const handleClick = ( e ) => {
    e.preventDefault()

    const queryParameters =
      'client_id=' + config.spotify.client_id +
      '&response_type=token' +
      '&redirect_uri=' + encodeURIComponent( location.href.replace( /\#.*/, '' ) )

    location.href = 'https://accounts.spotify.com/authorize?' + queryParameters
  }

  return(
    <a
      className={`${styles.button} btn-primary`}
      onClick={handleClick}
      >
      Log in to Spotify to Get Your Playlists &rarr;
    </a>
  )
}