import React from 'react'

import styles from './index.module.css'

export const Prompt = ({ locations, playlists }) => {
  const selectedPlaylists = playlists.filter( playlist => playlist.checked )

  return(
    <h2 className={styles.text}>
      {'Find '}
      {locations.length > 0 && (
        <strong>
          {locations.map( ( location, i ) =>
            location.location + ( i < locations.length - 1 ? ' + ' : '' )
          )}
        </strong>
      )}
      {' concerts for artists in your '}
      <strong>
        {
          selectedPlaylists.length ? (
            selectedPlaylists.map( ( playlist, i ) =>
              playlist.name + ( i < selectedPlaylists.length - 1 ? ' + ' : '' )
            )
          ) : (
            'public Spotify'
          )
        }
      </strong>
      {` playlist${ selectedPlaylists.length !== 1 ? 's' : '' }.`}
    </h2>
  )
}
