export default class Graph{
  constructor( el, options ){
    this.el = el
    if( typeof el === 'string' ){
      this.el = document.querySelectorAll( el )
    }
    if( el.nodeType === document.ELEMENT_NODE ){
      this.el = [ el ]
    }

    this.default = {
      data: undefined,
      width: '100%',
      height: '61.803%', //61.8033988749855
      interactive: false,
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      type: undefined
    }
    this.set( options )

    this.init()
  }

  set( options ){
    this.settings = {}
    for( var key in this.default ){
      if( this.default.hasOwnProperty( key ) ){
        if( options[ key ] ){
          if( key === 'padding' ){
            this.settings[ key ] =
              isNaN( options[ key ] ) ?
                options[ key ] :
                  {
                    top: options[ key ],
                    right: options[ key ],
                    bottom: options[ key ],
                    left: options[ key ]
                  }
          }
          else{
            this.settings[ key ] = options[ key ]
          }
        }
        else{
          this.settings[ key ] = this.default[ key ]
        }
      }
    }
    return this.settings
  }

  init(){
    return this.el.forEach( graph => this.build( graph ) )
  }

  build( graph ){
    this.ct = graph.getContext( '2d' )
    this.padding = this.settings.padding
    this.data = this.settings.data

    this.sizeCanvas = () => {
      this.width =
        String( this.settings.width ).indexOf( '%' ) !== -1 ?
          // percentage of container width minus padding
          parseInt( this.settings.width ) / 100 * ( graph.parentNode.offsetWidth - parseInt( getComputedStyle( graph.parentNode )[ 'paddingLeft' ] ) - parseInt( getComputedStyle( graph.parentNode )[ 'paddingRight' ] ) ) :
            parseInt( this.settings.width )

      this.height =
        String( this.settings.height ).indexOf( '%' ) !== -1 ?
          // percentage heights relative to width (might want to introduce percentage * container option)
          parseInt( this.settings.height ) / 100 * this.width :
            parseInt( this.settings.height )

      // detect retina, scale to display
      const backingStoreRatio =
        this.ct.webkitBackingStorePixelRatio ||
          this.ct.mozBackingStorePixelRatio ||
            this.ct.msBackingStorePixelRatio ||
              this.ct.oBackingStorePixelRatio ||
                this.ct.backingStorePixelRatio ||
                  1,
        scale = ( window.devicePixelRatio || 1 ) / backingStoreRatio

      graph.width = this.width * scale,
        graph.height = this.height * scale,
      graph.style.width = this.width + 'px',
        graph.style.width = this.width + 'px'
      this.ct.scale( scale, scale )

      // draw/redraw static graph
      if( !this.settings.interactive ){
        this.draw()
      }
    }
    this.sizeCanvas()
    addEventListener( 'resize', this.sizeCanvas.bind( this ) )

    // draw animated graph
    if( this.settings.interactive ){
      const requestAnimationFrame =
        window.requestAnimationFrame ||
          window.mozRequestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
              window.msRequestAnimationFrame ||
                function( callback ){
                  window.setTimeout( callback, 1000 / 60 )
                }
      let lastTime = null
      function animate( time ){
        if( lastTime != null ){
          this.draw( Math.min( 100, time - lastTime ) / 1000 )
        }
        lastTime = time
        this.animation = requestAnimationFrame( animate.bind( this ) )
      }
      this.animation = requestAnimationFrame( animate.bind( this ) )
    }
  }

  //fitStats(){
  fitStats( set ){
    var obj = this, // use bind instead?
      //arr = [],
      graphHeight = this.height - this.padding.top - this.padding.bottom,
      maxY = set.reduce( function( a, b ){
        return Math.max( a, b );
      } );

    /*var maxY = 0;
    for( var i = 0; i < this.data.length; i++ ){
      var newMaxY = this.data[ i ].value.reduce( function( a, b ){
        return Math.max( a, b );
      } );
      if( newMaxY > maxY )
        maxY = newMaxY;
    }
    // values relative to maximum value, subtracted from canvas height for rendering
    for( var i = 0; i < this.data.length; i++ ){
      arr.push(
        this.data[ i ].value.map( function( value ){
          return graphHeight + obj.padding.top - ( value / maxY * graphHeight );
        } )
      );
    }*/

    //return arr;

    // values relative to maximum value, subtracted from canvas height for rendering
    return set.map( function( value ){
      return graphHeight + obj.padding.top - ( value / maxY * graphHeight );
    } );
  }

  draw( step ){
    /*this.color = [];
    for( var i = 0; i < this.data.length; i++ )
      this.color.push( this.data[ i ].color ? this.data[ i ].color : 'rgba( 128, 128, 128, .5 )' );*/
    const color = [];
    for( var i = 0; i < this.data.length; i++ )
      color.push( this.data[ i ].color ? this.data[ i ].color : 'rgba( 128, 128, 128, .5 )' );

    // clear
    this.ct.clearRect( 0, 0, this.width, this.height );

    // draw
    this.pieStartAngle = 1.5 * Math.PI;
    this.drawSort = function( data, color ){
      var type = this.settings.type ? this.settings.type : data.type;
      if( type === 'line' )
        this.drawLine( step, data.value, color );
      else if( type === 'area' )
        this.drawArea( step, data.value, color );
      else if( type === 'pie' )
        this.drawPie( step, data.value, color );
      else // default
        this.drawColumn( step, data.value, color );
    }
    /*// single data type
    if( this.settings.type )
      //this.drawSort( this.settings.type );
      for( var i = 0; i < this.data.length; i++ ){

      }
    // multiple data types
    else
      for( var i = 0; i < this.data.length; i++ )
        this.drawSort( this.data[ i ].type );*/
    for( var i = 0; i < this.data.length; i++ )
      this.drawSort( this.data[ i ], color[ i ] );
  }

  drawColumn( step, value, color ){
    //console.log( 'column' );
  }

  drawLine( step, value, color ){
    // distance between points
    /*step =
      ( this.width - this.padding.right - this.padding.left ) /
        ( this.data[ 0 ].value.length - 1 );*/
    step =
      ( this.width - this.padding.right - this.padding.left ) /
        ( value.length - 1 ); // need to update for data sets that do not span full width (start late, etc)

    // draw
    /*for( var i = 0; i < this.data.length; i++ ){
      this.ct.beginPath();
      this.ct.moveTo( this.padding.left, this.fitStats()[ i ][ 0 ] );
      for( var j = 1; j < this.data[ i ].value.length; j++ ){
        //this.ct.arcTo( 2, 301, 42, 301, 28 );
        //this.ct.quadraticCurveTo( 323, 93, 309.4903802908, 84.16777609049 );
        this.ct.lineTo( this.padding.left + j * step, this.fitStats()[ i ][ j ] );
      }
      this.ct.strokeStyle = this.color[ i ];
      this.ct.stroke();
    }*/
    var fitStats = this.fitStats( value );
    this.ct.beginPath();
    this.ct.moveTo( this.padding.left, fitStats[ 0 ] );
    for( var i = 1; i < value.length; i++ ){
      //this.ct.arcTo( 2, 301, 42, 301, 28 );
      //this.ct.quadraticCurveTo( 323, 93, 309.4903802908, 84.16777609049 );
      this.ct.lineTo( this.padding.left + i * step, fitStats[ i ] );
    }
    this.ct.strokeStyle = color;
    this.ct.stroke();
  }

  drawArea( step, pt, color ){
    // distance between points
    /*step =
      ( this.width - this.padding.right - this.padding.left ) /
        ( this.data[ 0 ].value.length - 1 );*/
    step =
      ( this.width - this.padding.right - this.padding.left ) /
        ( pt.length - 1 ); // need to update for data sets that do not span full width (start late, etc)

    // draw
    /*for( var i = 0; i < this.data.length; i++ ){
      this.ct.beginPath();
      this.ct.moveTo( this.padding.left, this.height - this.padding.bottom );

      var pt = this.data[ i ].value;
      if( !!pt ){ // has data
        if( pt.constructor === Array )
          for( var j = 0; j < pt.length; j++ ){
            //this.ct.arcTo( 2, 301, 42, 301, 28 );
            //this.ct.quadraticCurveTo( 323, 93, 309.4903802908, 84.16777609049 );
            this.ct.lineTo( this.padding.left + j * step, this.fitStats()[ i ][ j ] );
          }
        else if( pt.constructor === Object ){
          for( var j = 0; j < pt.length; j++ ){

          }
        }
      }

      this.ct.lineTo( this.width - this.padding.right, this.height - this.padding.bottom );

      this.ct.fillStyle = i < this.color.length ? this.color[ i ] : this.color[ this.color.length - 1 ];
      this.ct.fill();
    }*/
    this.ct.beginPath();
    this.ct.moveTo( this.padding.left, this.height - this.padding.bottom );

    //var pt = this.data[ i ].value;
    if( !!pt ){ // has data
      var fitStats = this.fitStats( pt );
      if( pt.constructor === Array )
        for( var i = 0; i < pt.length; i++ ){
          //this.ct.arcTo( 2, 301, 42, 301, 28 );
          //this.ct.quadraticCurveTo( 323, 93, 309.4903802908, 84.16777609049 );
          this.ct.lineTo( this.padding.left + i * step, fitStats[ i ] );
        }
      else if( pt.constructor === Object ){
        for( var j = 0; j < pt.length; j++ ){

        }
      }
    }

    this.ct.lineTo( this.width - this.padding.right, this.height - this.padding.bottom );

    this.ct.fillStyle = color;
    this.ct.fill();
  }

  drawPie( step, value, color ){
    var center = {
        x: this.width / 2,
        y: this.height / 2
      },
      graphSpace = {
        width: this.width - this.padding.right - this.padding.left,
        height: this.height - this.padding.top - this.padding.bottom
      },
      radius = graphSpace.width < graphSpace.height ? graphSpace.width / 2 : graphSpace.height / 2,
      totalValue = 0;
      //totalValue = this.data.reduce( function( a, b ){ return a + b; } );
    for( var i = 0; i < this.data.length; i++ )
      totalValue += this.data[ i ].value;

    /*var startAngle = 1.5 * Math.PI,
      endAngle = 0;
    for( var i = 0; i < this.data.length; i++ ){
      endAngle = startAngle + ( ( this.data[ i ].value / totalValue ) * 2 * Math.PI );

      this.ct.beginPath();
      this.ct.moveTo( center.x, center.y );
      this.ct.arc( center.x, center.y, radius, startAngle, endAngle );
      //this.ct.closePath();
      this.ct.fillStyle = this.color[ i ];
      this.ct.fill();

      startAngle = endAngle;
    }*/
    var endAngle = this.pieStartAngle + ( ( value / totalValue ) * 2 * Math.PI );

    this.ct.beginPath();
    this.ct.moveTo( center.x, center.y );
    this.ct.arc( center.x, center.y, radius, this.pieStartAngle, endAngle );
    //this.ct.closePath();
    this.ct.fillStyle = color;
    this.ct.fill();

    this.pieStartAngle = endAngle;
  }
}