import '../simple-slide-show.css'
import '../style.css'

import './hammer.min.js'
import SimpleSlideShow from './simple-slide-show'


const slideShow = document.querySelectorAll( '.simple-slide-show' )
for( var i = 0; i < slideShow.length; i++ ){
  new SimpleSlideShow( slideShow[ i ] );
}


export const end = () => {
  // unmount autoplay etc
}