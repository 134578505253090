import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Locations } from './component/Locations/index.jsx'

import styles from './index.module.css'

export const Filter = ({ locations, onClickOption }) => {
  const [ focus, setFocus ] = useState( false )
  const [ value, setValue ] = useState( '' )

  const containerRef = useRef()

  const handleChange = ( event ) => {
    setValue( event.target.value )
  }

  const handleClickOption = ( location ) => {
    onClickOption( location )
    setFocus( false )
    setValue( '' )
  }

  const handleClickOutside = useCallback( ( event ) => {
    if(
      !containerRef.current ||
      !containerRef.current.contains( event.target )
    ){
      setFocus( false )
    }
  }, [] )

  useEffect( () => {
    document.addEventListener( 'click', handleClickOutside, true )
    return () => {
      document.removeEventListener( 'click', handleClickOutside, true )
    }
  }, [ handleClickOutside ] )

  return (
    <div className={styles.container} ref={containerRef}>
      <input
        className={styles.input}
        placeholder="Location"
        value={value}
        onChange={handleChange}
        onFocus={() => setFocus( true )}
        />
      <Locations
        options={locations}
        query={value}
        visibility={focus}
        onClick={handleClickOption}
        />
    </div>
  )
}