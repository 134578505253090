import React, { useEffect, useState } from 'react'

import { ConcertDate } from './component/ConcertDate/index.jsx'
import { Filter } from './component/Filter/index.jsx'

import styles from './index.module.css'

export const Concerts = ({ filterLocations, listings, locations, onClickLocation }) => {
  const [ filteredListings, setFilteredListings ] = useState( listings )

  useEffect( () => {
    if( filterLocations.length ){
      const filtered = []
      listings.forEach( group => {
        const date = Object.keys( group )[ 0 ]
        const dateFiltered = group[ date ].filter( concert =>
          filterLocations.some( filter => {
            if( filter.type === 'country' ){
              return concert.location.country === filter.location
            }
            else if( filter.type === 'state' ){
              return concert.location.state === filter.location
            }
            return concert.location.city === filter.location
          } )
        )
        if( dateFiltered.length ){
          filtered.push({
            [date]: dateFiltered
          })
        }
      } )
      setFilteredListings( filtered )
    }
    else{
      setFilteredListings( listings )
    }
  }, [ filterLocations, listings ] )

  return(
    <>
      {Object.keys( locations ).length > 0 &&
        <Filter locations={locations} onClickOption={onClickLocation} />
      }
      
      <ol className={styles.concerts}>
        {
          filteredListings.map( ( group, i ) => {
            const date = Object.keys( group )
            return(
              <ConcertDate
                key={ i }
                date={ date }
                concert={ group[ date ] }
                />
            )
          }
          )
        }
      </ol>
    </>
  )
}