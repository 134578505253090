import '../style.css'
import Graph from './Graph'


// shared reference
let graph

export const start = () => {
  // italicize ampersands outside href attributes
  document.querySelectorAll( '.col-text, figcaption, ol.reference li' ).forEach(
    text => {
      text.innerHTML = text.innerHTML.replace(
        /(href)="\S*&amp;\S*?"|&amp;/g, // first match inside href to filter those out
        ( match, href ) => {
          if( href ){
            return match
          }
          return '<em>' + match + '</em>'
        }
      )
    }
  )


  // furl references
  const buttonReferences = document.querySelector( 'button.references' )
  if( buttonReferences ){
    document.querySelector( 'button.references' ).addEventListener(
      'click',
      () => {
        console.log( 'furl' )
      }
    )
  }


  // quick reference: create
  const quickReference = document.createElement( 'ol' )
  quickReference.className = 'quick-reference'
  document.querySelectorAll( 'ol.reference > li' ).forEach(
    item => {
      const li = document.createElement( 'li' ),
        div = document.createElement( 'div' )

      if( item.id ){
        li.setAttribute( 'data-id', item.id )
      }
      li.appendChild( div )
      div.innerHTML = item.innerHTML

      quickReference.appendChild( li )
    }
  )
  document.body.appendChild( quickReference )

  // quick reference: interactivity
  const citations = document.querySelectorAll( 'a.citation' ),
    hideQuickReference = () => {
      for( let reference of document.querySelectorAll( 'ol.quick-reference > li' ) ){
        reference.classList.remove( 'on' )
      }
    }
  citations.forEach(
    citation => {
      citation.addEventListener(
        'click',
        e => {
          e.preventDefault()

          const reference = document.querySelector( 'ol.quick-reference > li[ data-id="' + e.currentTarget.getAttribute( 'href' ).slice( 1 ) + '" ]' )
          if( !reference.classList.contains( 'on' ) ){
            hideQuickReference()
          }
          reference.classList.toggle( 'on' )
        }
      )
    }
  )
  addEventListener( 'scroll', hideQuickReference )


  // init graphs
  graph = {
    energySources: document.querySelector( 'figure.energy-sources canvas' ),
    downwardIR: document.querySelector( 'figure.downward-i-r canvas' ),
    outgoingIR: document.querySelector( 'figure.outgoing-i-r canvas' ),
    fossilFuel: document.querySelector( 'figure.fossil-fuel canvas' ),
    carbon14: document.querySelector( 'figure.carbon-14 canvas' )
  }
  if( graph.energySources )
    graph.energySources = new Graph(
      graph.energySources,
      {
        type: 'pie',
        padding: {
          top: 30,
          right: 30,
          bottom: 30,
          left: 30
        },
        data: [
          {
            color: 'rgba( 255, 185, 20, .9 )',
            value: 121467
          },
          {
            color: 'rgba( 179, 18, 55, .9 )',
            value: 47
          },
          {
            color: 'rgba( 128, 128, 128, .9 )',
            value: 14
          },
          {
            color: 'rgba( 44, 159, 163, .9 )',
            value: 4
          }
        ]
      }
    )
  if( graph.downwardIR )
    graph.downwardIR = new Graph(
      graph.downwardIR,
      {
        type: 'line',
        data: [
          {
            color: '#f00',
            value: [ 60, 35, 58, 45, 25, 35, 23, 0 ]
          }
        ],
        padding: {
          top: 30,
          right: 30,
          bottom: 30,
          left: 30
        }
      }
    )
  if( graph.outgoingIR )
    graph.outgoingIR = new Graph(
      graph.outgoingIR,
      {
        type: 'line',
        data: [
          {
            color: 'rgba( 66, 152, 203, .9 )',
            value: [ 0, 60, 30, 45, 25, 45, 23, 75 ]
          },
          {
            color: 'rgba( 70, 192, 245, .7 )',
            value: [ 0, 30, 25, 57, 90, 65, 60, 0 ]
          }
        ],
        padding: {
          top: 30,
          right: 30,
          bottom: 30,
          left: 30
        }
      }
    )
  if( graph.fossilFuel ){
    var fossilFuelColors = [
      'rgba( 0, 0, 0, .6 )',
      'rgba( 0, 0, 0, .4 )'
    ]
    graph.fossilFuel = new Graph(
      graph.fossilFuel,
      {
        //type: 'area',
        xAxis: [ 1751, 2010 ], // placeholder, need some option for range
        yAxis: [
          {
            title: 'Million Metric Tons of Carbon per Year',
            color: fossilFuelColors[ 0 ]
          },
          {
            title: 'Parts per Million',
            color: fossilFuelColors[ 1 ]
          }
        ],
        data: [
          {
            type: 'area',
            yAxis: 1,
            color: fossilFuelColors[ 0 ],
            value: [ 0, 60, 30, 45, 25, 45, 23, 75 ]
          },
          {
            type: 'line',
            yAxis: 2,
            color: fossilFuelColors[ 1 ],
            value: [ 0, 30, 25, 57, 90, 65, 60, 0 ]
          }
        ],
        padding: {
          top: 30,
          right: 30,
          bottom: 30,
          left: 30
        }
      }
    )
  }
  if( graph.carbon14 ){
    graph.carbon14 = new Graph(
      graph.carbon14,
      {
        type: 'area',
        data: [
          {
            color: 'rgba( 66, 152, 203, .9 )',
            value: [ 0, 60, 30, 45, 25, 45, 23, 75 ]
          },
          {
            color: 'rgba( 70, 192, 245, .7 )',
            value: [ 0, 30, 25, 57, 90, 65, 60, 0 ]
          }
        ],
        padding: {
          top: 30,
          right: 10,
          bottom: 30,
          left: 60
        },
        interactive: true
      }
    )
  }
}


export const end = () => {
  const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame

  cancelAnimationFrame( graph.carbon14.animation )
}