import '../style.css'
import * as THREE from 'three'
import gsap from 'gsap'
// typedarray polyfill covered by Babel?
import palette from '../../../script/palette'


// shared reference
let colorLoop = null,
  render

export const start = () => {
  const webglAvailable = () => {
    try{
      var canvas = document.createElement( 'canvas' );
      return !!( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) );
    }
    catch( e ){
      return false;
    }
  }
  if( !webglAvailable() ){
    return document.body.classList.add( 'no-webgl' )
  }

  // scene 1: everything but the initial rendering of the final 2 center blocks
  var scene1 = {
    scene: new THREE.Scene(),
    light: {
      ambient: [
        new THREE.AmbientLight( 0xffffff ),
        new THREE.AmbientLight( 0xffffff, 0.8 )
      ],
      directional: [
        new THREE.DirectionalLight( 0xf5f5f5, 0.5 ),
        new THREE.DirectionalLight( 0xf5f5f5, 0.1 )
      ]
    },
    responsive: new THREE.Group(),
    block: new THREE.Group()
  };

  // scene 2: final 2 blocks, which need unique lighting initially
  var scene2 = {
    scene: new THREE.Scene(),
    light: {
      ambient: [
        new THREE.AmbientLight( 0xffffff ),
        new THREE.AmbientLight( 0xffffff, 0.8 )
      ],
      directional: [
        new THREE.DirectionalLight( 0xf5f5f5, 0.5 ),
        new THREE.DirectionalLight( 0xf5f5f5, 0.1 )
      ]
    },
    responsive: new THREE.Group(),
    block: new THREE.Group()
  };

  // camera
  var aspect = window.innerWidth / window.innerHeight,
    depth = 8,
    camera = new THREE.OrthographicCamera( -depth * aspect, depth * aspect, depth, -depth, 1, 1000 );
  camera.position.set( 0, 0, 10 );

  // renderer
  var renderer = new THREE.WebGLRenderer({
    antialias: true//,
    //alpha: true // currently unused
  });
  renderer.autoClear = false;
  renderer.setPixelRatio( window.devicePixelRatio || 1 );
  renderer.setSize( window.innerWidth, window.innerHeight );

  // create canvas
  document.querySelector( 'article.content' ).appendChild( renderer.domElement )

  // calculations
  var calc = {
    degreesToRadians: function( n ){
      return n * ( Math.PI / 180 );
    },
    diagonalYForX: function( x ){
      return ( x / Math.sin( this.degreesToRadians( 60 ) ) ) * Math.sin( this.degreesToRadians( 30 ) );
    },
    isometricScale: function( length ){
      return length / 0.816496580927726;
    },
    rotateXIsometric: -Math.atan( 1 / Math.sqrt( 2 ) ),
    viewHeight: depth * 2,
    viewWidth: function(){
      return depth * ( window.innerWidth / window.innerHeight ) * 2;
    }
  };
  //calc.cubeEdgeLength = ( calc.viewHeight * 0.32 ) / 2;
  calc.cubeEdgeLength = calc.viewHeight * 0.45 / 2;
  calc.cubeHalfDiagonalLength = ( ( calc.cubeEdgeLength / 2 ) / Math.sin( calc.degreesToRadians( 30 ) ) ) * Math.sin( calc.degreesToRadians( 60 ) );
  calc.cubeHalfDrift = calc.cubeEdgeLength * 0.14444444444444;
  calc.zSpaceBetweenFlushCubes =
    calc.isometricScale( calc.cubeEdgeLength ) / Math.sin( calc.degreesToRadians( 90 ) ) *
      Math.sin( Math.atan( 1 / Math.sqrt( 2 ) ) );

  // resize
  function canvasSize(){
    document.getElementsByTagName( 'canvas' )[ 0 ].style.width = window.innerWidth + 'px';
    document.getElementsByTagName( 'canvas' )[ 0 ].style.height = window.innerHeight + 'px';
    camera.left = -depth * ( window.innerWidth / window.innerHeight );
    camera.right = depth * ( window.innerWidth / window.innerHeight );
    camera.top = depth;
    camera.bottom = -depth;
    camera.updateProjectionMatrix();
    renderer.setSize( window.innerWidth, window.innerHeight );

    scaleResponsiveGroup();
  }
  function scaleResponsiveGroup(){
    var d = [ 'x', 'y', 'z' ],
      i;

    if( window.innerWidth < 660 ){ // somewhat arbitrary aesthetic break
      for( i = 0; i < d.length; i++ ){
        scene1.responsive.scale[ d[ i ] ] = 0.828125;
        scene2.responsive.scale[ d[ i ] ] = 0.828125;
      }
    }
    else{
      for( i = 0; i < d.length; i++ ){
        scene1.responsive.scale[ d[ i ] ] = 1;
        scene2.responsive.scale[ d[ i ] ] = 1;
      }
    }
  }
  window.addEventListener(
    'resize',
    function(){
      if( document.querySelector( 'canvas' ) ){
        canvasSize();

        // animation
        slowFrames = 0;
      }
    }
  );


  // color
  palette[ 'color' ] = []
  let unify = false,
    swap = false
  const setColor = () => {
    let i

    // background color
    scene1.scene.background = new THREE.Color( 'hsl( ' + palette.hue[ 0 ] + ', 100%, 50% )' )

    // three block colors
    const blockColor = [ 1, 2, 3, 4, 5 ]
    for( i = 0; i < 3; i++ ){
      let position = blockColor.splice( Math.floor( Math.random() * blockColor.length ), 1 )[ 0 ]

      palette.color.push({
        position: position,
        material: new THREE.MeshStandardMaterial({
          flatShading: true,
          roughness: 1,
          color: 'hsl( ' + palette.hue[ position ] + ', 100%, 50% )',
          emissive: 'hsl( ' + palette.hue[ position ] + ', 100%, 50% )',
          emissiveIntensity: 0.55
        })
      })
    }
    // colors that change for unification
    for( i = 0; i < 3; i++ ){
      palette.color.push({
        material: new THREE.MeshStandardMaterial({
          flatShading: true,
          roughness: 1,
          color: 'hsl( ' + palette.hue[ palette.color[ i ].position ] + ', 100%, 50% )',
          emissive: 'hsl( ' + palette.hue[ palette.color[ i ].position ] + ', 100%, 50% )',
          emissiveIntensity: 0.55
        })
      })
    }
    // additional changing face: on top + center blocks
    palette.color.push({
      material: new THREE.MeshStandardMaterial({
        flatShading: true,
        roughness: 1,
        color: 'hsl( ' + palette.hue[ palette.color[ 0 ].position ] + ', 100%, 50% )',
        emissive: 'hsl( ' + palette.hue[ palette.color[ 0 ].position ] + ', 100%, 50% )',
        emissiveIntensity: 0.55
      })
    })

    // animate colors
    const updateColor = () => {
      const durationSwap = 3
      const predictHue = ( hue, duration ) => {
        const hueChangeDuringSwap = durationSwap / ( palette.changeInterval / 1000 )

        if( hue + hueChangeDuringSwap <= 359 ){
          return hue + hueChangeDuringSwap
        }
        return hue + hueChangeDuringSwap - 359
      }
      const tweenColor = ( target, color ) => {
        gsap.to(
          target,
          palette.changeInterval / 1000,
          {
            ease: 'Linear.easeNone',
            r: color.r,
            g: color.g,
            b: color.b
          }
        )
      }
      const tweenColorSwap = ( target, color ) => {
        gsap.to(
          target,
          durationSwap,
          {
            ease: 'Linear.easeNone',
            r: color.r,
            g: color.g,
            b: color.b,
            onComplete: function(){
              swap = 'complete';
            }
          }
        )
      }

      // background color
      tweenColor(
        scene1.scene.background,
        new THREE.Color( 'hsl( ' + palette.hue[ 0 ] + ', 100%, 50% )' )
      )

      // blocks
      for( i = 0; i < 3; i++ ){
        tweenColor(
          palette.color[ i ].material.color,
          new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ i ].position ] + ', 100%, 50% )' )
        )
        tweenColor(
          palette.color[ i ].material.emissive,
          new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ i ].position ] + ', 100%, 50% )' )
        )
      }
      if( !unify ){
        for( i = 3; i < 6; i++ ){
          tweenColor(
            palette.color[ i ].material.color,
            new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ i - 3 ].position ] + ', 100%, 50% )' )
          )
          tweenColor(
            palette.color[ i ].material.emissive,
            new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ i - 3 ].position ] + ', 100%, 50% )' )
          )
        }
        tweenColor(
          palette.color[ 6 ].material.color,
          new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ 0 ].position ] + ', 100%, 50% )' )
        )
        tweenColor(
          palette.color[ 6 ].material.emissive,
          new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ 0 ].position ] + ', 100%, 50% )' )
        )
      }
      else{
        if( unify ){
          if( swap === false ){
            swap = true
            tweenColorSwap(
              palette.color[ 3 ].material.color,
              new THREE.Color( 'hsl( ' + predictHue( palette.hue[ palette.color[ 2 ].position ], durationSwap ) + ', 100%, 50% )' )
            )
            tweenColorSwap(
              palette.color[ 3 ].material.emissive,
              new THREE.Color( 'hsl( ' + predictHue( palette.hue[ palette.color[ 2 ].position ], durationSwap ) + ', 100%, 50% )' )
            )

            tweenColorSwap(
              palette.color[ 4 ].material.color,
              new THREE.Color( 'hsl( ' + predictHue( palette.hue[ palette.color[ 0 ].position ], durationSwap ) + ', 100%, 50% )' )
            )
            tweenColorSwap(
              palette.color[ 4 ].material.emissive,
              new THREE.Color( 'hsl( ' + predictHue( palette.hue[ palette.color[ 0 ].position ], durationSwap ) + ', 100%, 50% )' )
            )

            tweenColorSwap(
              palette.color[ 5 ].material.color,
              new THREE.Color( 'hsl( ' + predictHue( palette.hue[ palette.color[ 1 ].position ], durationSwap ) + ', 100%, 50% )' )
            )
            tweenColorSwap(
              palette.color[ 5 ].material.emissive,
              new THREE.Color( 'hsl( ' + predictHue( palette.hue[ palette.color[ 1 ].position ], durationSwap ) + ', 100%, 50% )' )
            )

            tweenColorSwap(
              palette.color[ 6 ].material.color,
              new THREE.Color( 'hsl( ' + predictHue( palette.hue[ palette.color[ 1 ].position ], durationSwap ) + ', 100%, 50% )' )
            )
            tweenColorSwap(
              palette.color[ 6 ].material.emissive,
              new THREE.Color( 'hsl( ' + predictHue( palette.hue[ palette.color[ 1 ].position ], durationSwap ) + ', 100%, 50% )' )
            )
          }
          else if( swap === 'complete' ){
            tweenColor(
              palette.color[ 3 ].material.color,
              new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ 2 ].position ] + ', 100%, 50% )' )
            )
            tweenColor(
              palette.color[ 3 ].material.emissive,
              new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ 2 ].position ] + ', 100%, 50% )' )
            )

            tweenColor(
              palette.color[ 4 ].material.color,
              new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ 0 ].position ] + ', 100%, 50% )' )
            )
            tweenColor(
              palette.color[ 4 ].material.emissive,
              new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ 0 ].position ] + ', 100%, 50% )' )
            )

            tweenColor(
              palette.color[ 5 ].material.color,
              new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ 1 ].position ] + ', 100%, 50% )' )
            )
            tweenColor(
              palette.color[ 5 ].material.emissive,
              new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ 1 ].position ] + ', 100%, 50% )' )
            )

            tweenColor(
              palette.color[ 6 ].material.color,
              new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ 1 ].position ] + ', 100%, 50% )' )
            )
            tweenColor(
              palette.color[ 6 ].material.emissive,
              new THREE.Color( 'hsl( ' + palette.hue[ palette.color[ 1 ].position ] + ', 100%, 50% )' )
            )
          }
        }
      }
    }
    if( colorLoop === null ){
      colorLoop = setInterval( updateColor, palette.changeInterval )
    }
  }
  setColor()


  // objects
  function blocks(){
    var blockIntro,
      blockExtend,
      blockCenter;

    function init(){
      aspect = window.innerWidth / window.innerHeight;

      scaleResponsiveGroup();

      // scenes
      scene1.light.directional[ 0 ].position.set( -10, 10, 10 );
      scene1.light.directional[ 1 ].position.set( 10, 20, 10 );
      scene2.light.directional[ 0 ].position.set( -10, 10, 10 );
      scene2.light.directional[ 1 ].position.set( 10, 20, 10 );

      // block 1
      blockIntro = {
        size: aspect > 1 ? depth * aspect * 2 : depth * 2
      };
      blockIntro.obj = new THREE.Mesh(
        new THREE.BoxGeometry( blockIntro.size, blockIntro.size, blockIntro.size ),
        [
          palette.color[ 2 ].material, // back left
          palette.color[ 1 ].material, // front right
          palette.color[ 0 ].material, // top
          palette.color[ 1 ].material, // bottom
          palette.color[ 0 ].material, // back right
          palette.color[ 2 ].material, // front left
        ]
      );
      blockIntro.obj.position.z = -blockIntro.size; // there is no perspective scaling, this only matters for overlaps and objects advancing beyond visible z space
      blockIntro.obj.name = 'blockIntroObj';

      // main/extending blocks
      blockExtend = {
        geometry: [
          new THREE.BoxGeometry(
            calc.isometricScale( calc.cubeEdgeLength ),
            calc.isometricScale( calc.cubeEdgeLength ),
            calc.isometricScale( calc.cubeEdgeLength )
          ),
          new THREE.BoxGeometry(
            calc.isometricScale( calc.cubeEdgeLength ),
            calc.isometricScale( calc.cubeEdgeLength ),
            calc.isometricScale( calc.cubeEdgeLength )
          ),
          new THREE.BoxGeometry(
            calc.isometricScale( calc.cubeEdgeLength ),
            calc.isometricScale( calc.cubeEdgeLength ),
            calc.isometricScale( calc.cubeEdgeLength )
          )
        ],
        group: new THREE.Group(),
        obj: []
      };
      blockExtend.group.name = 'blockExtendGroup';
      // move vertices 0-3 to negative x edge with vertices 4-7
      for( var i = 0; i < blockExtend.geometry.length; i++ )
        for( var j = 0; j < 4; j++ )
          blockExtend.geometry[ i ].vertices[ j ].x = calc.isometricScale( calc.cubeEdgeLength ) / -2;

      var blockExtendMaterial = [
        [
          palette.color[ 0 ].material,
          palette.color[ 0 ].material,
          palette.color[ 3 ].material, // (back) right face on middle right block in final formation
          palette.color[ 6 ].material, // (front) right face on middle block in final formation
          palette.color[ 3 ].material, // (front) top row on left block group in final formation
          palette.color[ 0 ].material,
        ],
        [
          palette.color[ 1 ].material,
          palette.color[ 1 ].material,
          palette.color[ 4 ].material, // (back) top face on middle left block in final formation
          palette.color[ 1 ].material,
          palette.color[ 1 ].material,
          palette.color[ 1 ].material,
        ],
        [
          palette.color[ 2 ].material,
          palette.color[ 2 ].material,
          palette.color[ 2 ].material,
          palette.color[ 2 ].material,
          palette.color[ 2 ].material,
          palette.color[ 5 ].material, // (back) left face on middle bottom block in final formation
        ]
      ];
      for( i = 0; i < 3; i++ ){
        blockExtend.obj.push(
          new THREE.Mesh(
            blockExtend.geometry[ i ],
            blockExtendMaterial[ i ]
          )
        );

        blockExtend.obj[ i ].rotation.x = calc.rotateXIsometric;
        blockExtend.obj[ i ].rotation.y = -Math.PI / 4;
        blockExtend.obj[ i ].name = 'blockExtend' + i + 'Obj';

        blockExtend.group.add( blockExtend.obj[ i ] );
      }
      blockExtend.obj[ 0 ].position.y = calc.cubeEdgeLength;
      blockExtend.obj[ 0 ].position.z = calc.zSpaceBetweenFlushCubes / 2;
      blockExtend.obj[ 0 ].rotation.z = calc.degreesToRadians( 90 );

      blockExtend.obj[ 1 ].position.x = calc.cubeHalfDiagonalLength;
      blockExtend.obj[ 1 ].position.y = -calc.cubeEdgeLength / 2;
      blockExtend.obj[ 1 ].position.z = calc.zSpaceBetweenFlushCubes / 2;
      blockExtend.obj[ 1 ].rotation.y = -Math.PI / 4 + calc.degreesToRadians( 90 );

      blockExtend.obj[ 2 ].position.x = -calc.cubeHalfDiagonalLength;
      blockExtend.obj[ 2 ].position.y = -calc.cubeEdgeLength / 2;
      blockExtend.obj[ 2 ].position.z = calc.zSpaceBetweenFlushCubes / 2;
      blockExtend.obj[ 2 ].rotation.z = calc.degreesToRadians( 180 );

      // final center blocks
      blockCenter = {
        geometry: new THREE.BoxGeometry(
          calc.isometricScale( calc.cubeEdgeLength ),
          calc.isometricScale( calc.cubeEdgeLength ),
          calc.isometricScale( calc.cubeEdgeLength )
        ),
        obj: []
      };
      for( i = 0; i < 2; i++ ){
        blockCenter.obj.push(
          new THREE.Mesh(
            blockCenter.geometry,
            [
              palette.color[ 1 ].material, // back left [1]
              palette.color[ 2 ].material, // front right
              palette.color[ 0 ].material, // top [0]
              palette.color[ 1 ].material, // bottom
              palette.color[ 2 ].material, // back right [2]
              palette.color[ 6 ].material // front left
            ]
          )
        );

        blockCenter.obj[ i ].rotation.x = -calc.rotateXIsometric;
        blockCenter.obj[ i ].rotation.y = -Math.PI / 4;
        blockCenter.obj[ i ].name = 'blockCenter' + i + 'Obj';
      }
      blockCenter.obj[ 0 ].position.z = calc.zSpaceBetweenFlushCubes * 2 + calc.zSpaceBetweenFlushCubes / 2;
      blockCenter.obj[ 1 ].position.z = -calc.zSpaceBetweenFlushCubes * 2 - calc.zSpaceBetweenFlushCubes / 2;

      scene1.block.add(
        blockIntro.obj
      );

      scene1.responsive.add(
        scene1.block
      );
      scene2.responsive.add(
        scene2.block
      );

      scene1.scene.add(
        scene1.light.directional[ 0 ],
        scene1.light.directional[ 1 ],
        scene1.light.ambient[ 0 ],
        scene1.light.ambient[ 1 ],
        scene1.responsive
      );
      scene2.scene.add(
        scene2.light.directional[ 0 ],
        scene2.light.directional[ 1 ],
        scene2.light.ambient[ 0 ],
        scene2.light.ambient[ 1 ],
        scene2.responsive
      );
    }

    function start( initial ){ // 3 sec
      if( initial )
        setColor( true );
      else{
        // reset lights
        scene1.light.directional[ 0 ].position.set( -10, 10, 10 );
        scene1.light.directional[ 1 ].position.set( 10, 20, 10 );
      }

      // spin all
      gsap.to(
        scene1.responsive.rotation,
        11,
        {
          ease: "power2.in",
          //z: calc.degreesToRadians( 1080 ),
          z: calc.degreesToRadians( 720 ),
          delay: 1
        }
      );
      gsap.to(
        scene1.responsive.rotation,
        8,
        {
          ease: "power2.inOut",
          y: calc.degreesToRadians( 360 ),
          delay: 3//,
          //onStart: function(){
          //  blockIntro.obj.position.z = 0;
          //}
        }
      );

      // blockIntro: spin
      gsap.to(
        blockIntro.obj.rotation,
        2,
        {
          ease: "power2.inOut",
          x: -calc.rotateXIsometric,
          y: -Math.PI / 4 + calc.degreesToRadians( 180 ),
          delay: 1
        }
      );
      // blockIntro: scale
      blockIntro.geometries = [
        blockIntro.obj.geometry.vertices[ 0 ],
        blockIntro.obj.geometry.vertices[ 1 ],
        blockIntro.obj.geometry.vertices[ 2 ],
        blockIntro.obj.geometry.vertices[ 3 ],
        blockIntro.obj.geometry.vertices[ 4 ],
        blockIntro.obj.geometry.vertices[ 5 ],
        blockIntro.obj.geometry.vertices[ 6 ],
        blockIntro.obj.geometry.vertices[ 7 ]
      ];
      function updateVertices(){
        blockIntro.obj.geometry.verticesNeedUpdate = true;
        blockIntro.obj.geometry.computeBoundingSphere();
      }
      function complete( i ){
        if( i == blockIntro.geometries.length - 1 ){
          scene1.block.remove(
            scene1.block.getObjectByName( 'blockIntroObj' )
          );

          facesToCubes();
        }
      }
      for( var i = 0; i < blockIntro.geometries.length; i++ ){
        var xSign =
          i < 4 ?
            '' : '-';
        var ySign =
          i === 0 || i === 1 || i === 4 || i === 5 ?
            '' : '-';
        var zSign =
          i === 0 || i === 2 || i === 5 || i === 7 ?
            '' : '-';
        gsap.to(
          blockIntro.geometries[ i ],
          3,
          {
            ease: "power2.out",
            x: xSign + calc.isometricScale( calc.cubeEdgeLength ) / 2,
            y: ySign + calc.isometricScale( calc.cubeEdgeLength ) / 2,
            z: zSign + calc.isometricScale( calc.cubeEdgeLength ) / 2,
            onUpdate: updateVertices,
            onCompleteParams: [ i ],
            onComplete: complete
          }
        );
      }
    }
    function facesToCubes(){ // 1 sec
      // zoom out/scale down throughout animation to loop final state to init state
      gsap.to(
        scene1.block.scale,
        19.75,
        {
          x: 0.5,
          y: 0.5,
          z: 0.5
        }
      );
      gsap.to(
        scene2.block.scale,
        19.75,
        {
          x: 0.5,
          y: 0.5,
          z: 0.5
        }
      );

      // reverse lights
      scene1.light.directional[ 0 ].position.set( 10, -10, 10 );
      scene1.light.directional[ 1 ].position.set( -10, -20, 10 );

      scene1.block.add( blockExtend.group );

      function updateVertices( i ){
        blockExtend.geometry[ i ].verticesNeedUpdate = true;
        blockExtend.geometry[ i ].computeBoundingSphere();
      }
      function complete( i, j ){
        if( i === 2 && j === 3 )
          splitCubes();
      }
      for( var i = 0; i < blockExtend.geometry.length; i++ )
        for( var j = 0; j < 4; j++ )
          gsap.to(
            blockExtend.geometry[ i ].vertices[ j ],
            1,
            {
              ease: "power2.inOut",
              x: calc.isometricScale( calc.cubeEdgeLength ) / 2,
              onUpdateParams: [ i ],
              onUpdate: updateVertices,
              onCompleteParams: [ i, j ],
              onComplete: complete
            }
          );
    }
    function splitCubes(){ // 5 sec for third block
      var tween = [
        [ // top block
          {
            y: '+=' + ( calc.cubeHalfDrift ),
            //y: calc.cubeEdgeLength + calc.cubeHalfDrift,
            onComplete: function(){
              extendCube( 0 );
            }
          },
          {
            ease: "power2.inOut",
            y: '-=' + calc.degreesToRadians( 360 )
          }
        ],
        [
          {
            x: '+=' + ( calc.cubeHalfDrift ),
            //x: calc.cubeHalfDiagonalLength + calc.cubeHalfDrift,
            y: '-=' + calc.diagonalYForX( calc.cubeHalfDrift ),
            //y: ( -calc.cubeEdgeLength / 2 ) - calc.diagonalYForX( calc.cubeHalfDrift ),
            delay: 1,
            onComplete: function(){
              extendCube( 1 );
            }
          },
          {
            ease: "power2.inOut",
            z: '+=' + calc.degreesToRadians( 360 ),
            delay: 1
          }
        ],
        [
          {
            x: '-=' + ( calc.cubeHalfDrift ),
            //x: -calc.cubeHalfDiagonalLength - calc.cubeHalfDrift,
            y: '-=' + calc.diagonalYForX( calc.cubeHalfDrift ),
            //y: ( -calc.cubeEdgeLength / 2 ) - calc.diagonalYForX( calc.cubeHalfDrift ),
            delay: 2,
            onComplete: function(){
              extendCube( 2 );
            }
          },
          {
            ease: "power2.inOut",
            y: '-=' + calc.degreesToRadians( 360 ),
            delay: 2
          }
        ]
      ];
      for( var i = 0; i < blockExtend.obj.length; i++ ){
        gsap.to(
          blockExtend.obj[ i ].position,
          3,
          tween[ i ][ 0 ]
        );
        gsap.to(
          blockExtend.obj[ i ].rotation,
          6,
          tween[ i ][ 1 ]
        );
      }
    }
    function extendCube( i ){ // 1 sec
      var j;

      function updateVertices( i ){
        blockExtend.geometry[ i ].verticesNeedUpdate = true;
        blockExtend.geometry[ i ].computeBoundingSphere();
      }
      function complete( i, j ){
        if( i === 1 && topVertices[ j ] === 5 )
          comeTogether();
      }
      if( i !== 2 ){
        var topVertices = [ 0, 1, 4, 5 ];
        for( j = 0; j < topVertices.length; j++ )
          gsap.to(
            blockExtend.geometry[ i ].vertices[ topVertices[ j ] ],
            1,
            {
              ease: "power2.out",
              y: calc.isometricScale( calc.cubeEdgeLength ) + calc.isometricScale( calc.cubeEdgeLength ) / 2,
              onUpdateParams: [ i ],
              onUpdate: updateVertices,
              onCompleteParams: [ i, j ],
              onComplete: complete
            }
          );
      }
      else{
        var rightVertices = [ 1, 3, 4, 6 ];
        for( j = 0; j < rightVertices.length; j++ )
          gsap.to(
            blockExtend.geometry[ 2 ].vertices[ rightVertices[ j ] ],
            1,
            {
              ease: "power2.out",
              z: -calc.isometricScale( calc.cubeEdgeLength ) - calc.isometricScale( calc.cubeEdgeLength ) / 2,
              onUpdateParams: [ i ],
              onUpdate: updateVertices
            }
          );
      }
    }
    function comeTogether(){ // 1 sec
      gsap.to(
        blockExtend.obj[ 0 ].position,
        1,
        {
          y: calc.cubeEdgeLength
        }
      );
      gsap.to(
        blockExtend.obj[ 1 ].position,
        1,
        {
          x: calc.cubeHalfDiagonalLength,
          y: -calc.cubeEdgeLength / 2
        }
      );
      gsap.to(
        blockExtend.obj[ 2 ].position,
        1,
        {
          x: -calc.cubeHalfDiagonalLength,
          y: -calc.cubeEdgeLength / 2,
          onComplete: function(){
            //createCenterBlock();
            gsap.delayedCall( // let three block tweens catch up to each other
              2,
              createCenterBlock
            );
          }
        }
      );
    }
    function createCenterBlock(){ // 5.5 sec to next
      //setTimeout( function(){ // let three block tweens catch up to each other
        scene2.block.add(
          blockCenter.obj[ 0 ],
          blockCenter.obj[ 1 ]
        );

        // keep spinning all in scene 1
        gsap.to(
          scene1.responsive.rotation,
          5.5,
          {
            ease: "power2.out",
            //z: '+=' + calc.degreesToRadians( 720 ),
            z: '+=' + calc.degreesToRadians( 360 ),
            onComplete: restartSpin
          }
        );
        gsap.to(
          scene1.block.rotation,
          9,
          {
            ease: "power2.inOut",
            x: -calc.rotateXIsometric * 2,
            //y: '+=' + calc.degreesToRadians( 1080 ),
            y: '+=' + calc.degreesToRadians( 1440 ),
            delay: 2.75
          }
        );
        gsap.to(
          scene2.block.rotation,
          9,
          {
            ease: "power2.inOut",
            x: -calc.rotateXIsometric * 2,
            //y: '+=' + calc.degreesToRadians( 1080 ),
            y: '+=' + calc.degreesToRadians( 1440 ),
            delay: 2.75
          }
        );

        // rotate center blocks
        gsap.to(
          scene2.responsive.rotation,
          5.5,
          {
            ease: "power2.out",
            //z: calc.degreesToRadians( 720 )
            z: calc.degreesToRadians( 360 )
          }
        );
        for( var i = 0; i < blockCenter.obj.length; i++ )
          gsap.to(
            blockCenter.obj[ i ].rotation,
            5.5,
            {
              ease: "power2.out",
              x: calc.rotateXIsometric
            }
          );

        // pull center blocks into greater cube
        gsap.to(
          blockCenter.obj[ 0 ].position,
          2.75,
          {
            ease: "power4.in",
            z: calc.zSpaceBetweenFlushCubes + calc.zSpaceBetweenFlushCubes / 2
          }
        );
        gsap.to(
          blockCenter.obj[ 1 ].position,
          2.75,
          {
            ease: "power4.in",
            z: -calc.zSpaceBetweenFlushCubes - calc.zSpaceBetweenFlushCubes / 2
          }
        );

        // reverse lights
        gsap.to(
          scene1.light.directional[ 0 ].position,
          3,
          {
            x: -10,
            y: 10,
            z: 10
          }
        );
        gsap.to(
          scene1.light.directional[ 1 ].position,
          3,
          {
            x: 10,
            y: 20,
            z: 10
          }
        );
      //}, 2000 );
    }
    function restartSpin(){
      unify = true;

      scene2.block.remove(
        scene2.block.getObjectByName( 'blockCenter0Obj' ),
        scene2.block.getObjectByName( 'blockCenter1Obj' )
      );
      scene1.block.add(
        blockCenter.obj[ 0 ],
        blockCenter.obj[ 1 ]
      );

      // reset responsive rotation
      gsap.killTweensOf( scene1.responsive.rotation ); // not sure it's necessary, but might as well
      gsap.killTweensOf( scene2.responsive.rotation ); // seems to occasionally continue tweening past this reset
      scene1.responsive.rotation.x = 0;
      scene1.responsive.rotation.y = 0;
      scene1.responsive.rotation.z = 0;
      scene2.responsive.rotation.x = 0;
      scene2.responsive.rotation.y = 0;
      scene2.responsive.rotation.z = 0;

      /*setTimeout(
        function(){
          // spin all
          gsap.to(
            scene1.responsive.rotation,
            11,
            {
              ease: "power2.in",
              z: calc.degreesToRadians( 1440 )
            }
          );
          gsap.to(
            scene1.responsive.rotation,
            8,
            {
              ease: "power2.inOut",
              y: calc.degreesToRadians( 360 ),
              delay: 2
            }
          );
          setTimeout(
            resetForStart,
            2000 // restart 2 seconds into rotation
          );
        },
        4250 // start 2 seconds before block.rotation finishes and facesToCubes() call to sync with start
      );*/
      gsap.delayedCall(
        4.25, // start 2 seconds before block.rotation finishes and facesToCubes() call to sync with start
        function(){
          // spin all
          gsap.to(
            scene1.responsive.rotation,
            11,
            {
              ease: "power2.in",
              //z: calc.degreesToRadians( 1440 ) // i think this should've been 1080 anyway
              z: calc.degreesToRadians( 720 )
            }
          );
          gsap.to(
            scene1.responsive.rotation,
            8,
            {
              ease: "power2.inOut",
              y: calc.degreesToRadians( 360 ),
              delay: 2
            }
          );
          gsap.delayedCall(
            2, // restart 2 seconds into rotation
            resetForStart
          );
        }
      );
    }
    function resetForStart(){
      var j;

      // clear all objects: blockExtendGroup, blockCenter0Obj, blockCenter1Obj
      for( var i = scene1.block.children.length - 1; i >= 0; i-- ){
        scene1.block.remove( scene1.block.getObjectByName( scene1.block.children[ i ].name ) );
      }

      // reset block group rotation
      gsap.killTweensOf( scene1.block.rotation );
      gsap.killTweensOf( scene2.block.rotation );
      scene1.block.rotation.x = 0;
      scene1.block.rotation.y = 0;
      scene2.block.rotation.x = 0;
      scene2.block.rotation.y = 0;

      // reset blockExtend
      for( i = 0; i < blockExtend.obj.length; i++ ){ // just in case
        gsap.killTweensOf( blockExtend.obj[ i ].position );
        gsap.killTweensOf( blockExtend.obj[ i ].rotation );
      }
      for( i = 0; i < blockExtend.geometry.length; i++ )
        for( j = 0; j < blockExtend.geometry[ i ].vertices.length; j++ )
          gsap.killTweensOf( blockExtend.geometry[ i ].vertices[ j ] ); // also just in case
      for( i = 0; i < blockExtend.geometry.length; i++ ){
        blockExtend.obj[ i ].rotation.x = calc.rotateXIsometric;

        blockExtend.geometry[ i ].verticesNeedUpdate = true; // necessary?
        blockExtend.geometry[ i ].computeBoundingSphere(); // necessary?

        // reset x to collapse
        for( j = 0; j < 4; j++ )
          blockExtend.geometry[ i ].vertices[ j ].x = calc.isometricScale( calc.cubeEdgeLength ) / -2;

        // reset y + z cube extensions
        if( i !== 2 ){
          var topVertices = [ 0, 1, 4, 5 ];
          for( j = 0; j < topVertices.length; j++ )
            blockExtend.geometry[ i ].vertices[ topVertices[ j ] ].y = calc.isometricScale( calc.cubeEdgeLength ) / 2;
        }
        else{
          var rightVertices = [ 1, 3, 4, 6 ];
          for( j = 0; j < rightVertices.length; j++ )
            blockExtend.geometry[ 2 ].vertices[ rightVertices[ j ] ].z = calc.isometricScale( calc.cubeEdgeLength ) / -2;
        }
      }
      blockExtend.obj[ 0 ].position.y = calc.cubeEdgeLength;
      blockExtend.obj[ 0 ].position.z = calc.zSpaceBetweenFlushCubes / 2;
      blockExtend.obj[ 0 ].rotation.y = -Math.PI / 4;
      blockExtend.obj[ 0 ].rotation.z = calc.degreesToRadians( 90 );

      blockExtend.obj[ 1 ].position.x = calc.cubeHalfDiagonalLength;
      blockExtend.obj[ 1 ].position.y = -calc.cubeEdgeLength / 2;
      blockExtend.obj[ 1 ].position.z = calc.zSpaceBetweenFlushCubes / 2;
      blockExtend.obj[ 1 ].rotation.y = -Math.PI / 4 + calc.degreesToRadians( 90 );
      blockExtend.obj[ 1 ].rotation.z = 0;

      blockExtend.obj[ 2 ].position.x = -calc.cubeHalfDiagonalLength;
      blockExtend.obj[ 2 ].position.y = -calc.cubeEdgeLength / 2;
      blockExtend.obj[ 2 ].position.z = calc.zSpaceBetweenFlushCubes / 2;
      blockExtend.obj[ 2 ].rotation.y = -Math.PI / 4;
      blockExtend.obj[ 2 ].rotation.z = calc.degreesToRadians( 180 );

      // reset scale
      gsap.killTweensOf( scene1.block.scale );
      gsap.killTweensOf( scene2.block.scale );
      var d = [ 'x', 'y', 'z' ];
      for( i = 0; i < d.length; i++ ){
        scene1.block.scale[ d[ i ] ] = 1;
        scene2.block.scale[ d[ i ] ] = 1;
      }

      // reset blockCenter objects
      for( i = 0; i < 2; i++ ){
        blockCenter.obj[ i ].rotation.x = -calc.rotateXIsometric;
        //blockCenter.obj[ i ].rotation.y = -Math.PI / 4;
      }
      blockCenter.obj[ 0 ].position.z = calc.zSpaceBetweenFlushCubes * 2 + calc.zSpaceBetweenFlushCubes / 2;
      blockCenter.obj[ 1 ].position.z = -calc.zSpaceBetweenFlushCubes * 2 - calc.zSpaceBetweenFlushCubes / 2;

      // reset switches
      unify = false;
      swap = false;

      // loop
      facesToCubes();
    }

    init();
    start( true );
  }
  blocks();


  // run
  var lastCalledTime = 0,
    fps,
    slowFrames = 0,
    delta;
  render = () => {
    // fps calculation
    if( !lastCalledTime ){
      lastCalledTime = Date.now();
      fps = 0;
    }
    delta = ( new Date().getTime() - lastCalledTime ) / 1000;
    lastCalledTime = Date.now();
    fps = 1 / delta;
    if( fps < 30 )
      slowFrames++;
    else
      slowFrames = 0;
    if( slowFrames > 20 )
      return; // kill animation

    //renderer.render( scene, camera );
    renderer.clear();
    renderer.render( scene1.scene, camera );
    renderer.render( scene2.scene, camera );
  }
  render();
  gsap.ticker.add( render );
}


export const end = () => {
  clearInterval( colorLoop )
  colorLoop = null

  gsap.ticker.remove( render )
  gsap.globalTimeline.clear()
}