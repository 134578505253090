import React, { useState } from 'react'

import { Concerts } from './component/Concerts/index.jsx'
import { GetPlaylists } from './component/GetPlaylists/index.jsx'
import locationHash from './service/locationHash';
import { Playlists } from './component/Playlists/index.jsx'
import { Prompt } from './component/Prompt/index.jsx'

export const PlaylistConcerts = () => {
    const [ authorized, setAuthorized ] = useState( locationHash.access_token )
    const [ concerts, setConcerts ] = useState( [] )
    const [ filterLocations, setFilterLocations ] = useState( [] )
    const [ locations, setLocations ] = useState( [] )
    const [ playlists, setPlaylists ] = useState( [] )
  
    return(
      <div>
        <Prompt locations={filterLocations} playlists={playlists} />

        {!authorized ? (
          <GetPlaylists />
        ) : (
          <>
            <div className="col-xs-12 col-sm-6">
              <Playlists
                playlists={playlists}
                setAuthorized={setAuthorized}
                setConcerts={setConcerts}
                setLocations={setLocations}
                setPlaylists={setPlaylists}
                />
            </div>
            <div className="concert col-xs-12 col-sm-6">
              {concerts.length > 0 &&
                <Concerts
                  filterLocations={filterLocations}
                  listings={concerts}
                  locations={locations}
                  onClickLocation={( location ) => {
                    if( !filterLocations.some( l => l.label === location.label ) ){
                      setFilterLocations( state => [
                        ...state,
                        { location: location.value[ 0 ], type: location.type }
                      ] )
                    }
                  } }
                  />
              }
            </div>
          </>
        )}
      </div>
    )
  }